import React from "react";

const Footer = () => {
  return (
    <div className="container2">
      <div class="alert alert-primary" role="alert">
        A simple primary alert—check it out!
      </div>
      <div className="row">
        <div className="col-6">
          <p>Test</p>
        </div>
        <div className="col-6">
          <p>Test</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
